import React, { useMemo } from "react"
import { Link } from "gatsby"

// import { FaRulerCombined } from "react-icons/fa"
// import { FaCalculator } from "react-icons/fa"
// import { FaTools } from "react-icons/fa"
// import { FaRandom } from "react-icons/fa"

import Layout from "../../components/Layout"
import NavBar from "../../components/NavBar/NavBar"
import SEO from "../../components/SEO/SEO"

import { isSSR } from "../../components/Script/script"
import { Title, Columns, Column } from "../../StyleComponents/styles"

const GoogleAds = React.lazy(() => import("../../components/ADS/ADS"))
const Icon = React.lazy(() => import("../../components/Icons/Icons"))

function SiteMapListPages(props) {
  const { node } = props.pageContext
  return (
    <Layout location={props.location}>
      <SEO
        title={node.SEOtitle}
        description={node.description}
        keywords={[node.keywords]}
      />
      <NavBar
        listPages={useMemo(() => {
          return [{ name: `${node.title}`, link: `/${node.path}/` }]
        }, [node.title, node.path])}
      />
      <div className="container">
        <Title>
          {node.icon && !isSSR && (
            <React.Suspense fallback={<span />}>
              <Icon
                d={node.icon}
                size={32}
                fillRule="evenodd"
                clipRule="evenodd"
              />
            </React.Suspense>
          )}
          {node.title}
        </Title>
        <br />
        <Columns>
          <Column>
            {node.type.map((element, index) => {
              return (
                <div key={index}>
                  <h3>{element.title}</h3>
                  <ul>
                    {element.pathAndName.map((e, i) => {
                      return (
                        <li key={`link-${i}`}>
                          <Link to={`/${e.link}/`}>{e.name}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </Column>
          <Column>
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
                <br />
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>
        <br />
      </div>
    </Layout>
  )
}

export default SiteMapListPages
